<template>
    <footer id="contact" style="background-color:#3d1657">
        <h3 class="text-center text-white text-2xl font-bold p-6">CONTACT ME</h3>
        <div class="social-network flex flex-wrap justify-center">
          <div class="flex w-full py-2 justify-center">
            <a href="https://twitter.com/GilbertTrinid17" target="_blank" class="px-6"><img src="@/assets/icons/ri_twitter-fill.svg"></a>
            <a href="https://www.linkedin.com/in/gilbert-trinidad-755417102/" target="_blank" class="px-6"><img src="@/assets/icons/ri_linkedin-fill.svg"></a>
          </div>
            <p class="flex w-full justify-center text-center py-2 text-white">Send me a message at gilbert.trinidad1@gmail.com</p>
            <span class="text-xs text-center" style="color:lightgray;">Copyright © 2023 | <router-link to="/politique">Privacy policy</router-link></span>
        </div>
        </footer>
</template>

<script>
export default {
  // Options spécifiques au composant de footer
};
</script>